{
  "name": "pbb-user-web-ui",
  "version": "3.29.0-SNAPSHOT",
  "apiVersion": "1.3.0.1",
  "scripts": {
    "ng": "ng",
    "start": "ng serve --configuration=local",
    "start:local": "cross-env HOST=http://localhost:3000 npm run start",
    "start:mock": "concurrently \"npm run start\" \"npm run mock-api\"",
    "watch": "ng build --watch --configuration development",
    "test": "jest --detectOpenHandles",
    "test:watch": "ng test pbb-user-web-ui --watch",
    "lint": "ng lint",
    "lint-staged": "lint-staged --relative",
    "locale": "node scripts/locale.js",
    "generate-sprite": "npx svg-sprite-generate -d src/assets/svgIcons -o src/assets/svgDefs.svg",
    "pretty": "npx prettier --write ./src/app",
    "mock-api": "cd mock-api && npm install && npm start",
    "generate-api": "rm -r projects/api/src/api.module && openapi-generator-cli generate -i projects/api/src/openapi2.yaml -g typescript-angular -o projects/api/src/api.module --additional-properties=ngVersion=15.2.10,npmName=pbbClient,supportsES6=true,withInterfaces=true,legacyDiscriminatorBehavior=false && npx prettier --write ./projects/api/src/api.module",
    "build": "sh -ac 'ng build --configuration=${NG_APP_ENV}' --source-map=true",
    "build:local": "NG_APP_ENV=local npm run build",
    "copy:env": "sh -ac 'cp src/environments/env-${NG_APP_ENV}.json dist/pbb-user-web-ui/browser/env.json'",
    "build:dev1": "ng build --source-map=true && cp src/environments/env-dev1.json dist/pbb-user-web-ui/browser/env.json",
    "build:qa1": "NG_APP_ENV=qa1 npm run copy:env",
    "build:beta1": "NG_APP_ENV=beta1 npm run copy:env",
    "build:fit1": "NG_APP_ENV=fit1 npm run copy:env",
    "build:perf1": "NG_APP_ENV=perf1 npm run copy:env",
    "build:perf2": "NG_APP_ENV=perf2 npm run copy:env",
    "build:prod1": "NG_APP_ENV=prod1 npm run copy:env",
    "build:prod1-b": "NG_APP_ENV=prod1-b npm run copy:env",
    "build:prod2": "NG_APP_ENV=prod2 npm run copy:env",
    "copy-build:local": "cp -R dist/* dist-local",
    "copy-build:dev1": "cp -R dist/* dist-dev1",
    "copy-build:qa1": "cp -R dist/* dist-qa1",
    "copy-build:beta1": "cp -R dist/* dist-beta1",
    "copy-build:fit1": "cp -R dist/* dist-fit1",
    "copy-build:perf1": "cp -R dist/* dist-perf1",
    "copy-build:perf2": "cp -R dist/* dist-perf2",
    "copy-build:prod1": "cp -R dist/* dist-prod1",
    "copy-build:prod1-b": "cp -R dist/* dist-prod1-b",
    "copy-build:prod2": "cp -R dist/* dist-prod2",
    "create-empty-dirs": "make-dir dist-dev1 dist-qa1 dist-beta1 dist-fit1 dist-perf1 dist-perf2 dist-prod1 dist-prod2 dist-prod1-b",
    "update-version": "node scripts/version.js"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "~17.3.11",
    "@angular/cdk": "^17.3.10",
    "@angular/common": "~17.3.11",
    "@angular/compiler": "~17.3.11",
    "@angular/core": "~17.3.11",
    "@angular/forms": "~17.3.11",
    "@angular/material": "^17.3.10",
    "@angular/platform-browser": "~17.3.11",
    "@angular/platform-browser-dynamic": "~17.3.11",
    "@angular/router": "~17.3.11",
    "@lokalise/node-api": "^7.3.1",
    "@ngrx/component": "^17.2.0",
    "@ngrx/effects": "^17.2.0",
    "@ngrx/store": "^17.2.0",
    "@ngrx/store-devtools": "^17.2.0",
    "@ngx-translate/core": "^14.0.0",
    "@ngx-translate/http-loader": "^7.0.0",
    "angular-google-tag-manager": "^1.9.0",
    "dayjs": "^1.11.7",
    "jwt-decode": "^3.1.2",
    "ngx-mask": "^17.1.1",
    "ngx-skeleton-loader": "^5.0.0",
    "rxjs": "^7.5.0",
    "tslib": "^2.3.0",
    "ua-parser-js": "^1.0.33",
    "uuid": "^8.3.2",
    "zone.js": "~0.14.7"
  },
  "devDependencies": {
    "@angular-builders/jest": "^17.0.0",
    "@angular-devkit/build-angular": "~17.3.8",
    "@angular-eslint/builder": "^17.3.10",
    "@angular-eslint/eslint-plugin": "^17.3.10",
    "@angular-eslint/eslint-plugin-template": "^17.3.10",
    "@angular-eslint/schematics": "^17.3.10",
    "@angular-eslint/template-parser": "^17.3.10",
    "@angular-jest/schematics": "0.2.0",
    "@angular/cli": "~17.3.8",
    "@angular/compiler-cli": "~17.3.11",
    "@angular/localize": "^17.3.11",
    "@ngrx/eslint-plugin": "^17.2.0",
    "@octokit/rest": "^18.12.0",
    "@openapitools/openapi-generator-cli": "^2.5.2",
    "@types/jasmine": "^3.10.0",
    "@types/jest": "^29.5.1",
    "@types/jwt-decode": "^3.1.0",
    "@types/node": "^12.11.1",
    "@types/ua-parser-js": "^0.7.36",
    "@types/uuid": "^8.3.4",
    "@typescript-eslint/eslint-plugin": "^6.0.0",
    "@typescript-eslint/parser": "^6.0.0",
    "atlassian-openapi": "^1.0.15",
    "concurrently": "^7.0.0",
    "cross-env": "^7.0.3",
    "decompress": "^4.2.1",
    "eslint": "^8.33.0",
    "eslint-config-prettier": "^8.3.0",
    "husky": "^7.0.4",
    "jasmine-core": "^3.10.0",
    "jest": "^29.5.0",
    "jest-environment-jsdom": "^29.7.0",
    "jest-marbles": "^3.0.3",
    "jest-preset-angular": "^14.1.1",
    "js-yaml": "^4.1.0",
    "json-server": "^0.17.0",
    "lint-staged": "^10.5.4",
    "make-dir-cli": "^3.0.0",
    "movefiles": "^1.0.1",
    "ng-packagr": "^17.3.0",
    "openapi-merge": "^1.3.2",
    "prettier": "^2.5.1",
    "speccy": "^0.2.2",
    "stylelint": "^14.3.0",
    "stylelint-config-standard-scss": "^3.0.0",
    "svg-sprite-generator": "^0.0.7",
    "typescript": "~5.4.5",
    "xml-js": "^1.6.11"
  },
  "lint-staged": {
    "*.ts": [
      "eslint --fix",
      "prettier --write --ignore-unknown"
    ],
    "src/**/*.scss": [
      "stylelint --fix"
    ],
    "*.{json,html}": "prettier --write --ignore-unknown"
  }
}
