<div *ngIf="!isPilot">
  <div class="content_main-footer-page mat-body-2">
    <ul class="content_main-footer-page__list footer-link-list" *ngFor="let menuItem of footerLinks; let k = index">
      <li class="content_main-footer-page__list--menu-title text-grey-subtitle mb-16">
        <span id="footer-menu-label-{{ k }}">{{ menuItem.menuLabel | translate }}</span>
      </li>
      <li class="footer-links" *ngFor="let item of menuItem.options; let i = index">
        <a
          *ngIf="item.url"
          id="footer-link-{{ k }}-{{ i }}"
          target="_blank"
          class="link-text-footer"
          href="{{ item.url | translate }}"
          rel="noopener"
        >
          {{ item.optionLabel | translate }}
        </a>
      </li>
    </ul>
    <div class="flex-col">
      <pbb-lang-toggle></pbb-lang-toggle>
    </div>
  </div>
  <hr class="mt-24 mb-24" />

  <div class="footer-social-container">
    <span id="footer-social-title" class="footer-social-title text-grey-subtitle">{{
      'FOOTER.SOCIAL.TITLE' | translate
    }}</span>
    <div class="footer-social-links">
      <a
        *ngFor="let social of socialLinks; let s = index"
        [attr.aria-label]="social.label | translate"
        mat-icon-button
        id="social-link-{{ s }}"
        href="{{ social.url }}"
        target="_blank"
        rel="noopener"
      >
        <pbb-svg-icon [width]="'25px'" [height]="'25px'" [icon]="social.icon"></pbb-svg-icon>
      </a>
    </div>
  </div>
</div>
