export const FORMAT_PHONE = '+0-000-000-0000';

export const CAROUSEL_SLIDES = [
  {
    imageUrlKey: 'COMMON.CAROUSEL_SLIDES[0].IMAGE_URL',
    imageTitleKey: 'COMMON.CAROUSEL_SLIDES[0].IMAGE_TITLE',
    imageAltKey: 'COMMON.CAROUSEL_SLIDES[0].IMAGE_ALT',
  },
  {
    imageUrlKey: 'COMMON.CAROUSEL_SLIDES[1].IMAGE_URL',
    imageTitleKey: 'COMMON.CAROUSEL_SLIDES[1].IMAGE_TITLE',
    imageAltKey: 'COMMON.CAROUSEL_SLIDES[1].IMAGE_ALT',
  },
];

export const interacLogo =
  'iVBORw0KGgoAAAANSUhEUgAAABkAAAAYCAYAAAAPtVbGAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAJgSURBVHgBvZU9aBNhGMf/994lTaKNQZ2MipOKU0QsdLKSSBcVdOlSSVaLFUelOJosCoK0a5vRDq0iImgiXbREEDPVtotgEicjmtak+bg7n/e53NloEWnv+oNL3nty9/yfr/eNAsJ8GTmnGyIFt1GMBW34e1YxX0SOGUL9BI8woaeEDnUIXmKKIYFdwDORjyWB5bLlXoNHhINAf8jktWuZrNUV5/v2dACjD4JIz/rZtu1MpLNy1XKcnu1D+atAmCJPxtuoNRRMjjWwXLJy4E9Zv2uk/Cc1cpR+3IdK19lM3ufcn7m1F/do/eqDhrPHdSRiHX7+3apKYgb2kbtKdVNP1ki5sKL1RFlrgG0zeT8K9OIAOZK2Wl0gm7PKMH6phcMHTIxNBZA43WHbySM65t/46D3w846ITWFFxfyihqXPKr58E8gkN9g+RalfnwxC5nPooMGObOYWeyt+dbCDCpUuSs+ND3b+FpHp5Yo+PL1bx/k7e3DqqPE7u4bAxMgGB7KZK11HcSqX7Ifsy8RI0/ndNLdofH/Q3HKdjDepB1SWWJsuHa8z61wqu2z/okdElkE2kAUoIhnVjYtNSFepRJsvm3AI/42zT6IUVYZGMVfUaG3g/cN1tt+83HIi3i4sslQSPHY2j575nbF1AxaRR4CM3mburR+j90O8f1wTkSUqrPbOgDVp7hxt7GXgRIdG1DLIjVmru1cqCWcS3W/ynEvcFpBwJhe6R0K+6M3J39NZeT55gaYG9CdGS52WN3Ln/vip0D+adXREd7g/GEVZ4Aa0nkdiqiqStIzARUwS0Iar2V+yIORM+Kr3wQAAAABJRU5ErkJggg==';

export const SOCIAL_LINKS = [
  { url: 'https://www.tiktok.com/@paywithkonek?lang=en', label: 'FOOTER.SOCIAL.TIKTOK.LABEL', icon: 'tiktok' },
  { url: 'https://www.youtube.com/@PayWithKONEK', label: 'FOOTER.SOCIAL.YOUTUBE.LABEL', icon: 'youtube' },
  { url: 'https://www.instagram.com/paywithkonek/', label: 'FOOTER.SOCIAL.INSTA.LABEL', icon: 'instagram' },
  {
    url: 'https://www.linkedin.com/company/paywithkonek/about/?viewAsMember=true',
    label: 'FOOTER.SOCIAL.LINKEDIN.LABEL',
    icon: 'linkedin',
  },
];
